@import "../../../styles/mixins.scss";

.aboveImage {
  div {
    margin: 0 !important;
    bottom: 0;
  }
}

.styleBg {
  div {
    height: 100%;
    max-width: 10rem !important;
    @include respond(desktop) {
      max-width: 85% !important;
    }
  }
}

.behind {
  div {
    margin: 0 !important;
    bottom: 0;
    z-index: -1;
  }
}

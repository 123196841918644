.ratio {
  aspect-ratio: 16/9;
  img {
    object-fit: cover;
  }
}

.bgCard {
  background-color: var(--bg);
  @apply p-12 lg:p-20;
}

.awardList {
  img {
    aspect-ratio: 1;
  }
}

.highlight {
  background-color: var(--highlight);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 85%;
  z-index: -1;
  height: 75%;
  top: -5%;
}

.highlightMobile {
  background-color: var(--highlight);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  z-index: 0;
  height: 75%;
  top: -30%;

  @media only screen and (max-width: 768px) {
    height: 20rem;
    top: -9rem;
  }
  @media only screen and (max-width: 600px) {
    height: 15rem;
    top: -7rem;
  }
  @media only screen and (max-width: 450px) {
    height: 10rem;
    top: -3rem;
  }

}

.highlightImage {
  img {
    object-fit: contain;

    @media only screen and (max-width: 450px) {
      top: 4rem !important;
      height: 10rem !important;
    }
  }
}

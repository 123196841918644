.overlayBg {
  top: 0;
  height: 100%;
  z-index: -1;

  img {
    object-fit: cover;
    object-position: top;
  }
}

// Container Heights //

.defaultHeight {
  @apply xl:min-h-[420px];
}

.extendedHeight {
  @apply min-h-[650px]  xl:min-h-[625px];
}

// End Container Heights //

.paragraph {
  color: var(--color);

}

.paragraphVariant {
  color: var(--mcolor);
  @media (min-width: 1024px) {
    color: var(--color);
  }
}

.altColor{
  color: var(--altColor);
}
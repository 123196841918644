.galleryImage {
	div {
		height: 100%;
		aspect-ratio: var(--ar);
		img {
			object-fit: cover;
		}
	}
}

.galleryImageHalf {
	div {
		height: 100%;
		aspect-ratio: var(--ar);
		img {
			object-fit: cover;
		}
	}
}

.bgCard {
	background-color: rgba(255, 255, 255, 0.05);
	min-height: 33rem;
}

.doubleColumn {
	@apply lg:columns-2 gap-12;
}

.singleColumn {
	@apply columns-1;
}

.titleVariant {
  color: var(--mcolor);
  @media (min-width: 1024px) {
    color: var(--color);
  }
}

.title {
  color: var(--color);
}

.bannersGrid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6 px-6  lg:px-12;
  div:nth-child(even) {
    @apply md:translate-y-[-50%];
  }
}

.featuredBannerContainer {
  @apply relative min-h-[560px] flex flex-col justify-end;
}

.textGalleyBg {
  img {
    object-fit: cover;
  }
}
